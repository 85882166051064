<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>B端数据管理</el-breadcrumb-item>
      <el-breadcrumb-item>学校信息管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header @back="goBack"></el-page-header>
    <!-- 筛选 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row">
        <el-col>
          <span class="title_class">学校信息筛选</span>
        </el-col>
      </el-row>
      <el-form
        :model="queryForm"
        class="filter_Form"
        label-width="100px"
        ref="queryFormRef"
      >
        <el-row :gutter="50">
          <el-col :span="6">
            <el-form-item label="学校省市区" prop="schoolArea">
              <el-cascader
                placeholder="请选择"
                v-model="queryForm.schoolArea"
                ref="schoolAreaRef"
                :options="areasData"
                style="width: 100%"
                :props="{
                  expandTrigger: 'hover',
                  label: 'name',
                  value: 'areaId',
                  children: 'areas',
                }"
                @change="provincesHandleChange('search')"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="学校名称" prop="schoolName">
              <el-input
                v-model="queryForm.schoolName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="学校组别" prop="level">
              <el-select
                placeholder="请选择"
                v-model="queryForm.level"
                style="width: 100%"
              >
                <el-option
                  v-for="item in dict_group"
                  :key="item.dictCode"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row :gutter="20" type="flex" justify="end">
        <el-col :span="2">
          <el-button
            type="primary"
            style="width: 100%"
            @click="getDataList('search')"
            >查询</el-button
          >
        </el-col>
        <el-col :span="2">
          <el-button type="primary" plain style="width: 100%" @click="reset"
            >重置</el-button
          >
        </el-col>
      </el-row>
    </el-card>
    <!-- 学校列表 -->
    <el-card style="margin-top: 15px">
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="9">
          <span class="title_class">学校信息列表</span>
        </el-col>
      </el-row>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="已通过" name="through">
          <el-table
            header-cell-class-name="tableHeaderStyle"
            :data="dataList"
            key="through"
            border
            stripe
          >
            <el-table-column label="序号" type="index"></el-table-column>
            <el-table-column label="省市区" prop="provence">
              <template slot-scope="scope">
                <span
                  >{{ scope.row.province }}-{{ scope.row.city }}-{{
                    scope.row.area
                  }}</span
                >
              </template>
            </el-table-column>
            <el-table-column label="学校名称" prop="schoolName">
              <template slot-scope="scope">
                <span>{{ scope.row.schoolName }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="负责老师"
              prop="masterTeacherName"
            ></el-table-column>
            <el-table-column
              label="手机号"
              prop="masterTeacherPhone"
            ></el-table-column>
            <el-table-column label="学校类型" prop="schoolType">
              <template slot-scope="scope">
                <span v-if="scope.row.schoolType + '' === '0'">公立学校</span>
                <span v-else-if="scope.row.schoolType + '' === '1'"
                  >私立学校</span
                >
                <span v-else>外籍子女学校</span>
              </template>
            </el-table-column>
            <el-table-column label="国家" prop="nationality"></el-table-column>
            <el-table-column
              label="审批人"
              prop="approveUserName"
            ></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="待审核" name="reviewed">
          <el-table
            header-cell-class-name="tableHeaderStyle"
            :data="dataList"
            border
            key="reviewed"
            stripe
          >
            <el-table-column label="序号" type="index"></el-table-column>
            <el-table-column label="省市区" prop="provence">
              <template slot-scope="scope">
                <span
                  >{{ scope.row.province }}-{{ scope.row.city }}-{{
                    scope.row.area
                  }}</span
                >
              </template>
            </el-table-column>
            <el-table-column label="学校名称" prop="schoolName">
              <template slot-scope="scope">
                <span>{{ scope.row.schoolName }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="负责老师"
              prop="masterTeacherName"
            ></el-table-column>
            <el-table-column
              label="手机号"
              prop="masterTeacherPhone"
            ></el-table-column>
            <el-table-column label="学校类型" prop="schoolType">
              <template slot-scope="scope">
                <span v-if="scope.row.schoolType + '' === '0'">公立学校</span>
                <span v-else-if="scope.row.schoolType + '' === '1'"
                  >私立学校</span
                >
                <span v-else>外籍子女学校</span>
              </template>
            </el-table-column>
            <el-table-column label="国家" prop="nationality"></el-table-column>
            <el-table-column label="操作" width="120px">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="auditApprovalClicked(scope.row)"
                  >同意</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="auditRefusedClicked(scope.row)"
                  >拒绝</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="已驳回" name="rejected">
          <el-table
            header-cell-class-name="tableHeaderStyle"
            :data="dataList"
            border
            key="rejected"
            stripe
          >
            <el-table-column label="序号" type="index"></el-table-column>
            <el-table-column label="省市区" prop="provence">
              <template slot-scope="scope">
                <span
                  >{{ scope.row.province }}-{{ scope.row.city }}-{{
                    scope.row.area
                  }}</span
                >
              </template>
            </el-table-column>
            <el-table-column label="学校名称" prop="schoolName">
              <template slot-scope="scope">
                <span>{{ scope.row.schoolName }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="负责老师"
              prop="masterTeacherName"
            ></el-table-column>
            <el-table-column
              label="手机号"
              prop="masterTeacherPhone"
            ></el-table-column>
            <el-table-column label="学校类型" prop="schoolType">
              <template slot-scope="scope">
                <span v-if="scope.row.schoolType + '' === '0'">公立学校</span>
                <span v-else-if="scope.row.schoolType + '' === '1'"
                  >私立学校</span
                >
                <span v-else>外籍子女学校</span>
              </template>
            </el-table-column>
            <el-table-column label="国家" prop="nationality"></el-table-column>
            <el-table-column
              label="审批人"
              prop="approveUserName"
            ></el-table-column>
            <el-table-column label="操作" width="80px" fixed="right">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="showDeleteDialog(scope.row)"
              >删除</el-button
            >
            </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.pageNum"
        :page-sizes="[10, 20, 40, 80]"
        :page-size="queryForm.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 编辑信息的dialog -->
    <el-dialog
      title="操作内容"
      :visible.sync="refusedInfoDialogVisible"
      width="40%"
      @close="refusedInfoDialogClose"
    >
      <el-form
        :model="auditForm"
        label-width="100px"
        ref="refusedInfoFormRef"
        :rules="refusedInfoRules"
        style="width: 100%"
      >
        <el-row>
          <el-col :span="18">
            <el-form-item label="拒绝原因" prop="refused">
              <el-input
                type="textarea"
                v-model="auditForm.refused"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="refusedInfo">提 交</el-button>
      </span>
    </el-dialog>
    <!-- 免费课程信息的dialog -->
    <el-dialog
      title="开通免费课程"
      :visible.sync="openFreeCoursesDialogVisible"
      width="70%"
    >
      <el-row class="title_row" align="middle" type="flex">
        <el-col :span="15">
          <span class="title-class">您将为该学校开通下面免费课程</span>
        </el-col>
      </el-row>
      <el-table
        header-cell-class-name="tableHeaderStyle"
        :data="courseList"
        border
        stripe
      >
        <el-table-column
          label="课程分类"
          prop="category"
          :formatter="categoryFormatter"
        ></el-table-column>
        <el-table-column label="课程名称" prop="productName"></el-table-column>
        <!-- <el-table-column
          label="课程类型"
          prop="category"
          :formatter="subTypeCategoryFormatter"
        ></el-table-column> -->
        <el-table-column label="价格" prop="price">
          <template slot-scope="scope">
            <span style="color: red"> ￥{{ scope.row.price }}</span>
          </template>
        </el-table-column>
        <el-table-column label="有效期类型">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.handType === 6" type="success">免费</el-tag>
            <el-tag v-else-if="scope.row.handType === 1" type="warning"
              >有效天数</el-tag
            >
            <el-tag v-else type="danger">到期时间</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="有效天数" prop="days">
          <template slot-scope="scope">
            <span style="color: #409eff">{{ scope.row.days }}</span>
          </template>
        </el-table-column>
        <el-table-column label="过期时间" prop="endTime"></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
      </el-table>
      <el-row :gutter="20" type="flex" justify="end" style="margin-top: 20px">
        <el-col :span="4">
          <el-button
            type="primary"
            style="width: 100%"
            @click="openCoursesClicked"
            >确认开通</el-button
          >
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 删除学校弹窗 -->
    <el-dialog
      title="删除学校信息"
      :visible.sync="deleteDialogVisible"
      @close="deleteSchoolDialogClose"
      width="30%"
    >
      <el-form :model="deleteForm" label-width="90px" :rules="deleteSchoolRules"  ref="deleteSchoolFormRef">
        <el-form-item prop="text">
          <el-input v-model="deleteForm.text"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteSchoolDataClicked">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getAllAreaList, getApproveSchoolList, deleteSchoolData, addSysSchool, approveSchoolTeacher, getTbProductListInfoPage, secondaryLinkageCourseQuery, addTbProductSchoolList } from '@/http/api'
export default {
  data () {
    return {
      deleteDialogVisible: false,
      deleteForm: {
        deleteState: true,
        text: ''
      },
      deleteSchoolRules: {
        text: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      openFreeCoursesDialogVisible: false,
      refusedInfoDialogVisible: false,
      dict_group: this.$userInfo.dict_group(),
      hotCountry: { value: 'China', label: '中国' },
      total: 0,
      activeName: 'reviewed',
      dict_courseType: this.$userInfo.dict_courseType(),
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        approve: 1
      },
      editInfoForm: {
        schoolArea: [],
        schoolName: '',
        schoolType: '',
        schoolAddress: '',
        nationality: '',
        remark: ''
      },
      courseQueryForm: {
        activityType: this.$chnEngStatusCode.activityCourseType,
        openType: this.$chnEngStatusCode.OPEN_TYPE_DEFAULT,
        remark: '学校审核开通免费课',
        isFree: 0,
        pageNum: 1,
        pageSize: 999
      },
      openFreeCoursesForm: {},
      auditForm: {},
      dict_schoolType: this.$userInfo.getDataList(this, 'school_type').then(value => { this.dict_schoolType = value }),
      dict_countries: this.$userInfo.dict_countries(),
      editInfoRules: {
        schoolArea: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolName: [{ required: true, message: '请输入', trigger: 'blur' }],
        schoolType: [{ required: true, message: '请选择', trigger: 'change' }],
        schoolAddress: [{ required: true, message: '请输入', trigger: 'blur' }],
        nationality: [{ required: true, message: '请选择', trigger: 'change' }],
        remark: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      refusedInfoRules: {
        refused: [{ required: true, message: '请输入', trigger: 'blur' }]
      },
      courseList: [],
      // courseTotal: 0,
      dataList: [],
      schoolClickedId: '',
      areasData: [],
      editInfoDialogVisible: false
    }
  },
  created () {
    this.getDataList()
    this.getAllAreaList()
  },
  methods: {
    getAllAreaList () {
      getAllAreaList({}).then((res) => {
        this.areasData = res.data
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    goBack () {
      this.$router.back()
    },
    // 获取课程分类二级菜单
    getSecondaryLinkageCourseQuery () {
      secondaryLinkageCourseQuery().then((res) => {
        this.dict_category = res.data
        this.getFreeCourseList()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 审核状态切换
    handleClick (tab, event) {
      console.log('tab', tab)
      if (tab.index === '0') {
        this.queryForm.approve = 1
      } else if (tab.index === '1') {
        this.queryForm.approve = 0
      } else {
        this.queryForm.approve = 2
      }
      this.getDataList('search')
    },
    // 获取学校列表信息
    getDataList (getType) {
      if (getType === 'search') {
        this.queryForm.pageNum = 1
        this.queryForm.pageSize = 10
      }
      getApproveSchoolList(this.queryForm).then((res) => {
        console.log('getSysSchoolList', res)
        this.dataList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 学校省市区选择
    provincesHandleChange (value) {
      console.log('value', value)
      if (value === 'addSchool') {
        this.editInfoForm.provinceCode = this.editInfoForm.schoolArea[0]
        this.editInfoForm.cityCode = this.editInfoForm.schoolArea[1]
        this.editInfoForm.areaCode = this.editInfoForm.schoolArea[2]
        const deliveryAreaName = this.$refs.schoolAreaRef.getCheckedNodes()[0].pathLabels.join('/')
        const areaList = deliveryAreaName.split('/')
        this.editInfoForm.province = areaList[0]
        this.editInfoForm.city = areaList[1]
        this.editInfoForm.area = areaList[2]
      } else {
        this.queryForm.provinceCode = this.queryForm.schoolArea[0]
        this.queryForm.cityCode = this.queryForm.schoolArea[1]
        this.queryForm.areaCode = this.queryForm.schoolArea[2]
        const deliveryAreaName = this.$refs.schoolAreaRef.getCheckedNodes()[0].pathLabels.join('/')
        const areaList = deliveryAreaName.split('/')
        this.queryForm.province = areaList[0]
        this.queryForm.city = areaList[1]
        this.queryForm.area = areaList[2]
      }
    },
    // 添加学校点击事件
    addSchoolInfoClicked () {
      console.log('2222')
      this.editInfoDialogVisible = true
    },
    // 修改学校信息
    renewalEditClicked (changeData) {
      this.editInfoForm = changeData
      this.editInfoForm.schoolType = this.editInfoForm.schoolType + ''
      this.editInfoForm.schoolArea = [changeData.provinceCode + '', changeData.cityCode + '', changeData.areaCode + '']
      console.log('changeData', changeData)
      this.editInfoDialogVisible = true
    },
    // 保存学校信息
    editInfo () {
      addSysSchool(this.editInfoForm).then((res) => {
        this.$message.success('操作成功')
        this.editInfoDialogVisible = false
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 同意点击事件
    auditApprovalClicked (rowData) {
      this.schoolClickedId = rowData.id
      this.schoolId = rowData.schoolId
      this.showCourseList()
    },
    // 确认开通
    openCoursesClicked () {
      this.auditForm.approve = 1
      this.auditForm.id = this.schoolClickedId
      this.approveSchoolTeacher()
    },
    // 拒绝点击事件
    auditRefusedClicked (rowData) {
      this.auditForm.approve = 2
      this.auditForm.id = rowData.id
      this.refusedInfoDialogVisible = true
    },
    // 拒绝原因提交
    refusedInfo () {
      this.$refs.refusedInfoFormRef.resetFields()
      this.refusedInfoDialogVisible = false
      this.approveSchoolTeacher()
    },
    refusedInfoDialogClose () {
      this.$refs.refusedInfoFormRef.resetFields()
    },
    // 获取免费课程数据弹窗
    showCourseList () {
      this.getSecondaryLinkageCourseQuery()
      this.openFreeCoursesDialogVisible = true
    },
    getFreeCourseList () {
      console.log('courseQueryForm', this.courseQueryForm)
      const schoolData = JSON.parse(localStorage.getItem('saveSchoolInfo'))
      this.courseQueryForm.userId = schoolData.userId
      getTbProductListInfoPage(this.courseQueryForm).then((res) => {
        this.courseList = res.data.list
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 学校审核接口
    approveSchoolTeacher () {
      approveSchoolTeacher(this.auditForm).then((res) => {
        console.log('approveSchoolTeacher______', res)
        this.openFreeCoursesDialogVisible = false
        this.openCoursesInfoDetail()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 开通课程
    openCoursesInfoDetail () {
      console.log('courseQueryForm', this.courseQueryForm)
      this.courseQueryForm.productList = this.courseList
      this.courseQueryForm.type = this.$chnEngStatusCode.OPEN_TYPE_DEFAULT
      this.courseQueryForm.season = this.$chnEngStatusCode.defaultSeason
      this.courseQueryForm.schoolId = this.schoolId
      addTbProductSchoolList(this.courseQueryForm)
        .then((res) => {
          console.log('addTbProductSchoolList____', res)
          this.$message.success('开通成功！')
          this.getDataList()
        })
        .catch((err) => {
          console.log('返回的err', err)
        })
    },
    showDeleteDialog (row) {
      this.deleteDialogVisible = true
      this.deleteForm.schoolId = row.id
      // this.$confirm('是否确认删除该学校?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(async () => {
      //   deleteSchoolData({
      //     id: row.id
      //   }).then((res) => {
      //     this.$message.success('删除成功')
      //     this.getExamListData()
      //   }).catch((err) => {
      //     console.log('err', err)
      //   })
      // }).catch(() => { })
    },
    // 查看学校详情
    detailsDataClicked (scopeData) {
      console.log('scopeData', scopeData)
    },
    // 重置搜索条件
    reset () {
      this.$refs.queryFormRef.resetFields()
      this.getDataList()
    },
    editInfoDialogClose () {
      this.$refs.editInfoFormRef.resetFields()
    },
    // 删除学校信息
    deleteSchoolDataClicked () {
      deleteSchoolData(this.deleteForm).then((res) => {
        this.deleteDialogVisible = false
        this.$message.success('删除成功')
        this.getDataList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    deleteSchoolDialogClose () {
      this.$refs.deleteSchoolFormRef.resetFields()
    },
    // openFreeCoursesDialogClose () {
    //   this.$refs.openFreeCoursesFormRef.resetFields()
    // },
    handleSizeChange (newSize) {
      this.queryForm.pageSize = newSize
      this.getDataList()
    },
    handleCurrentChange (newCurPage) {
      this.queryForm.pageNum = newCurPage
      this.getDataList()
    },
    courseHandleSizeChange (newSize) {
      this.courseQueryForm.pageSize = newSize
      this.getFreeCourseList()
    },
    courseHandleCurrentChange (newCurPage) {
      this.courseQueryForm.pageNum = newCurPage
      this.getFreeCourseList()
    },
    subTypeCategoryFormatter (data) {
      var temp = '-'
      for (const key in this.dict_courseType) {
        if (Object.hasOwnProperty.call(this.dict_courseType, key)) {
          const element = this.dict_courseType[key]
          if (element.dictValue === data.category) {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    groupFormatter (data) {
      var temp = '未设置'
      for (const key in this.dict_group) {
        if (Object.hasOwnProperty.call(this.dict_group, key)) {
          const element = this.dict_group[key]
          if (element.dictValue === data.openLevel + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    categoryFormatter (data) {
      var temp = '-'
      for (const key in this.dict_category) {
        if (Object.hasOwnProperty.call(this.dict_category, key)) {
          const element = this.dict_category[key]
          if (element.id === data.category) {
            temp = element.typeName
          }
        }
      }
      return temp
    },
    handTypeFormatter (data) {
      var temp = '-'
      for (const key in this.dict_handType) {
        if (Object.hasOwnProperty.call(this.dict_handType, key)) {
          const element = this.dict_handType[key]
          if (element.dictValue === data.handType + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    }
  }
}
</script>

<style lang="less">
.div_algin_right {
  text-align: right;
}
.table-backview {
  width: 100%;
}
.table-column-backview {
  width: 100%;
  height: 85px;
  margin: 20px 0px;
  display: flex;
  flex-direction: row;
  // align-items: center;
}
.left-image {
  width: 150px;
  height: 85px;
}
.right-backview {
  display: flex;
  margin-left: 20px;
  flex-direction: column;
}
.right-title {
  font-size: 16px;
  color: black;
}
.right-content {
  font-size: 12px;
  color: rgb(58, 58, 58);
}
</style>
